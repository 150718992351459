import React from 'react';
import withErrorHandler from '../../../error/WithErrorHandler';
import './JobDetailExpensesSection.scss';
import { Tabs, Tab } from 'react-bootstrap';
import JobDetailExpenses from '../Subsections/JobDetailExpenses';
import JobDetailExpensesPerDiem from '../Subsections/JobDetailExpensesPerDiem';
import { ACTIONS, useJobDetail } from '../../../providers/jobDetailsProvider';

const TYPE_EXPENSE_GENERAL = 1;
const TYPE_EXPENSE_PER_DIEM = 2;
const TABS_KEY = "jobDetailExpensesSectionTab";

const JobDetailExpensesSection = ({ reportMode }) => {
    const [jobDetailContext, setJobDetailContext] = useJobDetail();

    return (
        <>
            {reportMode ? (
                <>
                    <h5>General Expenses</h5>
                    <JobDetailExpenses
                        reportMode={reportMode}
                        type={TYPE_EXPENSE_GENERAL}
                    />
                    <h5>Per Diem Expenses</h5>
                    <JobDetailExpensesPerDiem
                        reportMode={reportMode}
                        type={TYPE_EXPENSE_PER_DIEM}
                    />
                </>
            ) : (
                <Tabs
                    className="font-weight-medium job-detail-time-section p-0"
                    activeKey={jobDetailContext[TABS_KEY]}
                    onSelect={active => setJobDetailContext({
                        action: ACTIONS.TOGGLE_TAB,
                        payload: { tab: TABS_KEY, active }
                    })}
                >
                    <Tab eventKey={TYPE_EXPENSE_GENERAL} title="General Expenses">
                        <JobDetailExpenses
                            type={TYPE_EXPENSE_GENERAL}
                        />
                    </Tab>
                    <Tab eventKey={TYPE_EXPENSE_PER_DIEM} title="Per Diem" >
                        <JobDetailExpensesPerDiem
                            type={TYPE_EXPENSE_PER_DIEM}
                        />
                    </Tab>
                </Tabs>
            )}
        </>
    );
};

export default withErrorHandler(JobDetailExpensesSection);