import React from 'react';
import moment from "moment";
import Api from "../api/Api";
import jsPDF from "jspdf";
import { toast } from "react-toastify";
import { EMPLOYEE_ROLES } from "../constants";

const ROLE_TECHNICIAN = "1";
const ROLE_FOREMAN = "2";
const ROLE_EXECUTIVE = "3";

const ATTACHMENT_TYPE_NOTE = 2;

const roles = {
  [ROLE_TECHNICIAN]: { name: "Technician", class: "technician" },
  [ROLE_FOREMAN]: { name: "Foreman", class: "foreman" },
  [ROLE_EXECUTIVE]: { name: "Executive", class: "executive" },
};

const getTimeAsHours = (decimalHours) => {
  let hh = Math.floor(decimalHours / 60);
  let ss = Math.floor(decimalHours) % 60;
  return `${hh < 10 ? `0${hh}` : hh}:${ss < 10 ? `0${ss}` : ss}`;
};

const downloadFile = async (url) => {
  if (!url) {
    return;
  }
  try {
    const blobFile = await Api.proxyFile(url).catch(() => undefined);
    if (!blobFile) {
      return;
    }

    const a = document.createElement("a");
    a.href = URL.createObjectURL(blobFile.data);
    let fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
    a.download = fileName.includes(".") ? fileName : fileName + ".png";
    a.click();
  } catch (err) {
    const msg = err.response?.data?.length ? err.response.data[0].msg : "There was an error with your request";
    toast.error(msg);
  }
};

const toLowerCase = (value) => {
  if (!value) {
    return null;
  }
  if (typeof value !== "string") {
    return value;
  }
  return value.toLowerCase();
};

const payrollToPDF = (
  current_week_payroll_data,
  payroll_data,
  start_date,
  end_date,
  category
) => {
  const {
    employees_with_registered_time_this_week,
    jobs_with_registered_time_this_week,
  } = current_week_payroll_data;
  const doc = new jsPDF("p", "mm", "a4");
  const source = `
        <div style="font-size: 4px; width: ${doc.internal.pageSize.getWidth()}px; padding: 5px">
            <div style="display: flex; justify-content: center; font-size: 6px; margin-bottom: 4px;">Payroll: ${category}</div>
            ${employees_with_registered_time_this_week
      .map((employee, i) => {
        const employeePayroll = payroll_data.find(
          (pd) => pd.employee.id === employee.id
        );
        const hoursByDate = employeePayroll.job_hours.reduce((p, c) => {
          const timesByDate = c.times.reduce((p, c) => {
            if (p[c.date]) {
              p[c.date].time += (c.time - c.lunch_break);
              p[c.date].travel_time += c.travel_time;
            } else {
              if (c.time > 0 || c.travel_time > 0) {
                const jobData =
                  jobs_with_registered_time_this_week.find(
                    (j) => j.id === c.jobId
                  );
                p[c.date] = {
                  time: c.time - c.lunch_break,
                  travel_time: c.travel_time,
                  customer: jobData.customer.name,
                  so_number: jobData.so_number,
                };
              } else {
                p[c.date] = {
                  time: c.time - c.lunch_break,
                  travel_time: c.travel_time,
                  customer: "",
                  so_number: "",
                };
              }
            }
            return p;
          }, {});
          Object.keys(timesByDate).forEach((date) => {
            if (p[date]) {
              p[date].time += timesByDate[date].time;
              p[date].travel_time += timesByDate[date].travel_time;
              p[date].customer += timesByDate[date].customer;
              p[date].so_number += timesByDate[date].so_number;
            } else {
              p[date] = timesByDate[date];
            }
          });
          return p;
        }, {});
        return employeePayroll
          ? `
                    <div style="background: #EAF2FF; display: flex; flex-direction: column; padding: 1px; width: 100%; text-align: center; border-top: 1px solid #3D7FE5; border-bottom: 1px solid #3D7FE5;">
                        <span class="font-weight-medium">${`${employee.firstName} ${employee.lastName}`}</span>
                        <small style="color: gray">${roles[employee.role].name
          }</small>
                    </div>
                    <table style="margin: 2px 2px 4px 2px; width: calc(100% - 4px)">
                        <thead style="border-bottom: 1px solid #eee">
                            <tr>
                                <th>Date</th>
                                <th>Customer</th>
                                <th>SO #</th>
                                <th>Drive Time</th>
                                <th>Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${Object.keys(hoursByDate)
            .map(
              (date) => `
                                <tr>
                                    <td>${moment(date).format(
                "MM/DD/YYYY"
              )}</td>
                                    <td>${hoursByDate[date].customer}</td>
                                    <td>${hoursByDate[date].so_number}</td>
                                    <td>${getTimeAsHours(
                hoursByDate[date].travel_time
              )}</td>
                                    <td>${getTimeAsHours(
                hoursByDate[date].time
              )}</td>
                                </tr>`
            )
            .join("")}
                        </tbody>
                    </table>
                    ${(i + 1) % 3 === 0
            ? '<div style="margin-bottom: 46px"></div>'
            : ""
          }
                `
          : "";
      })
      .join("")}
    </div>`;
  doc.setFont("rubik");
  doc.html(source, {
    margin: 3,
    callback: (doc) => {
      doc.save(
        `Payroll-${moment(start_date).format("MM/DD/YYYY")}to${moment(
          end_date
        ).format("MM/DD/YYYY")}`
      );
    },
  });
};

const parseEmployeeSelect = employee => (
  {
    key: employee.id || employee.key,
    label: employee.name || `${employee.firstName} ${employee.lastName}`,
    value: employee.id || employee.key,
    role: employee.role
  }
);

const getAllCrew = (job) =>
  job.crews
    .flatMap((crew) => [
      ...crew.technicians.map((c) => ({
        id: c.employee_id,
        name: `${c.employee.firstName} ${c.employee.lastName}`,
      })),
      ...crew.foremans.map((c) => ({
        id: c.employee_id,
        name: `${c.employee.firstName} ${c.employee.lastName}`,
      })),
    ]);

const getNewCrewNotes = (authContext, jobDays, job) => {
  let crewNotes = [];
  jobDays.forEach((jd) => {
    job.crews.forEach((crew) => {
      const attachments = jd.attachments.filter((at) => at.crew_id === crew.id);
      const noteAttachments = attachments.filter(
        (a) => a.type === ATTACHMENT_TYPE_NOTE &&
          (
            authContext.currentUser?.last_sync_crew_notes === null ||
            moment(authContext.currentUser?.last_sync_crew_notes).isBefore(a.updated_at)
          )
      );
      crewNotes = crewNotes.concat(noteAttachments);
    });
  });
  return crewNotes;
};

const getRole = (employee) => {
  const s = EMPLOYEE_ROLES.find(s => s.value === employee?.role);
  return s ? (
    <span className={`${s.class} badge p-2 ml-2`}>
      <i className="fas fa-circle mr-2"></i>
      {s.name}
    </span>
  ) : '';
};

export {
  downloadFile,
  toLowerCase,
  payrollToPDF,
  getTimeAsHours,
  parseEmployeeSelect,
  getAllCrew,
  getNewCrewNotes,
  getRole
};