import React, { useEffect, useState } from 'react';
import withErrorHandler from '../../../error/WithErrorHandler';
import './JobDetailTimeSection.scss';
import { Tabs, Tab } from 'react-bootstrap';
import JobDetailTimeEmployees from '../Subsections/JobDetailTimeEmployees';
import JobDetailStandByTime from '../Subsections/JobDetailStandByTime';
import JobDetailTimeEmployeesReport from '../Subsections/JobDetailTimeEmployeesReport';
import { getAllCrew } from '../../../Utils/Utils';
import { ACTIONS, useJobDetail } from '../../../providers/jobDetailsProvider';

const SCROLL_STEP = 120;
const EMPLOYEES = 'employees';
const TABS_KEY = "jobDetailTimeSectionTab";

const JobDetailTimeSection = ({ reportMode }) => {
    const [jobDetailContext, setJobDetailContext] = useJobDetail();
    const [allCrew, setAllCrew] = useState([]);

    useEffect(() => {
        const allCrew = getAllCrew(jobDetailContext.job);
        setAllCrew(allCrew);
    }, [jobDetailContext.job]);

    return (
        reportMode ? (
            <>
                <h5>Worked Time</h5>
                <JobDetailTimeEmployeesReport />
                <h5 className="mb-0">Stand By Time</h5>
                <JobDetailStandByTime
                    jobDays={jobDetailContext.jobDays}
                />
            </>
        ) : (
            <Tabs
                className="font-weight-medium job-detail-time-section p-0"
                activeKey={jobDetailContext[TABS_KEY]}
                onSelect={active => setJobDetailContext({
                    action: ACTIONS.TOGGLE_TAB,
                    payload: { tab: TABS_KEY, active }
                })}
            >
                <Tab eventKey={EMPLOYEES} title="Employees">
                    {jobDetailContext.jobDays.length ? (
                        <div className="tab-time-employees">
                            <div className="employees col-2 float-left p-0">
                                <div className="flex-table header" role="rowgroup">
                                    <div className="flex-row" role="columnheader">Employees ({allCrew.length})</div>
                                </div>
                                <div className="row-container">
                                    {allCrew.map((e, i) =>
                                        <div key={i} className="flex-table row" role="rowgroup">
                                            <div key={e.id} className="flex-row col-12" role="cell">
                                                <span className="employee-name">{`${e.name}`}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div onClick={e => e.currentTarget.nextSibling.scrollLeft = e.currentTarget.nextSibling.scrollLeft - SCROLL_STEP}
                                className='arrow left'>
                                <span><i className="fas fa-chevron-left"></i></span>
                            </div>
                            <JobDetailTimeEmployees jobDays={jobDetailContext.jobDays} job={jobDetailContext.job} />
                            <div onClick={e => e.currentTarget.previousSibling.scrollLeft = e.currentTarget.previousSibling.scrollLeft + SCROLL_STEP}
                                className='arrow right'>
                                <span><i className="fas fa-chevron-right"></i></span>
                            </div>
                        </div>
                    ) : (
                        <div className="flex-table row no-data-row" role="rowgroup">
                            <div className="flex-row" role="cell">No clocks recorded</div>
                        </div>
                    )}
                </Tab>
                <Tab eventKey="stand_by" title="Stand by Time" >
                    <JobDetailStandByTime
                        jobDays={jobDetailContext.jobDays}
                    />
                </Tab>
            </Tabs>
        )
    );
};

export default withErrorHandler(JobDetailTimeSection);