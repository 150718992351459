import React from "react";
import { JOB_STATUSES } from "../../constants";
import withErrorHandler from "../../error/WithErrorHandler";

const JobStatus = ({ job }) => {
  const status = JOB_STATUSES.find(s => s.value === job.status);
  const defaultStatus = parseInt(job.status) === -1 ? 'N/A' : '';

  return status ? (
    <span className={`${status.class} badge p-2 ml-2`}>
      {status.icon && <i className="fas fa-circle mr-2"></i>}
      {status.name}
    </span>
  ) : defaultStatus;

};

export default withErrorHandler(JobStatus);
