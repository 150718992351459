import React, { useCallback, useEffect, useState } from "react";
import Api from "../api/Api";
import withErrorHandler from "../error/WithErrorHandler";
import { Row, InputGroup, FormControl, Spinner, Button } from "react-bootstrap";
import EmployeeModal from "./EmployeeModal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./EmployeesList.scss";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import EmployeeActions from "./EmployeeActions";
import {
  EMPLOYEE_STATUSES,
  EMPLOYEE_ROLES,
  EMPLOYEE_CATEGORIES,
} from "../constants";
import { RefreshCw } from "react-feather";

const PAGE_SIZE = 10;

const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc",
};

const EmployeesList = () => {
  const [sortBy, setSortBy] = useState("firstName");
  const [direction, setDirection] = useState(SORT_ORDER.ASC);
  const [data, setData] = useState({
    employees: [],
    pagination: { page: 1, pageCount: 0, pageSize: PAGE_SIZE, rowCount: 0 },
  });
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const TABLE_COLUMNS = (onSort) => [
    {
      dataField: "firstName",
      text: "First Name",
      classes: "text-truncate align-middle",
      headerStyle: { width: "20%" },
      sort: true,
      formatter: (cell) => cell || "-",
      onSort,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      classes: "text-truncate align-middle",
      headerStyle: { width: "20%" },
      formatter: (cell) => cell || "-",
      sort: true,
      onSort,
    },
    {
      dataField: "contact",
      text: "Contact Info",
      classes: "text-truncate align-middle",
      headerStyle: { width: "20%" },
      sort: true,
      onSort,
    },
    {
      dataField: "active",
      text: "",
      classes: "text-truncate align-middle",
      headerStyle: { width: "10%" },
      headerFormatter: (column) => (
        <div role="columnheader">
          <select
            className="status-select"
            onChange={filterStatusChange}
            value={selectedStatus}
          >
            <option value="">Status</option>
            {EMPLOYEE_STATUSES.map((s, i) => (
              <option key={i} value={s.value}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
      ),
      formatter: (cell) => (
        <span className={`${cell ? "active" : "inactive"} badge p-2`}>
          {<i className="fas fa-circle mr-2"></i>}
          {cell ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      dataField: "category_id",
      text: "",
      classes: "text-truncate align-middle",
      headerStyle: { width: "10%" },
      headerFormatter: (column) => (
        <div role="columnheader">
          <select
            className="status-select"
            onChange={filterCategoryChange}
            value={selectedCategory}
          >
            <option value="">Category</option>
            {EMPLOYEE_CATEGORIES.map((s, i) => (
              <option key={i} value={s.value}>
                {s.category}
              </option>
            ))}
          </select>
        </div>
      ),
      formatter: (cell) => (
        <span
          className={`${cell === "1" ? "insured" : "non-insured"} badge p-2`}
        >
          {<i className="fas fa-circle mr-2"></i>}
          {cell === "1" ? "Insured" : "Non-Insured"}
        </span>
      ),
    },
    {
      dataField: "role",
      text: "",
      classes: "text-truncate align-middle",
      headerStyle: { width: "10%" },
      headerFormatter: (column) => (
        <div role="columnheader">
          <select
            className="status-select"
            onChange={filterRoleChange}
            value={selectedRole}
          >
            <option value="">Role</option>
            {EMPLOYEE_ROLES.map((s, i) => (
              <option key={i} value={s.value}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
      ),
      formatter: (cell) => {
        let s = EMPLOYEE_ROLES.find((s) => s.value === cell);
        return s ? (
          <span className={`${s.class} badge p-2`}>
            <i className="fas fa-circle mr-2"></i>
            {s.name}
          </span>
        ) : (
          "N/A"
        );
      },
    },
  ];

  const fetchEmployees = useCallback(() => {
    setLoading(true);
    Api.getEmployees({
      page: data.pagination.page,
      pageSize: data.pagination.pageSize,
      search: search,
      role: selectedRole,
      status: selectedStatus,
      category: selectedCategory,
      sortBy,
      direction,
    })
      .then((res) => {
        setLoading(false);
        setData({
          ...res.data,
        });
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
      });
  }, [data.pagination.page, data.pagination.pageSize, direction, sortBy, search, selectedCategory, selectedRole, selectedStatus]);

  useEffect(() => {
    fetchEmployees();
  }, [
    data.pagination.page,
    data.pagination.pageSize,
    refresh,
    sortBy,
    direction,
    fetchEmployees,
  ]);

  const filterRoleChange = (evt) => {
    setSelectedRole(evt.currentTarget.value);
    setData({
      employees: [],
      pagination: { page: 1, pageCount: 0, pageSize: PAGE_SIZE, rowCount: 0 },
    });
    setRefresh(!refresh);
  };

  const filterCategoryChange = (evt) => {
    setSelectedCategory(evt.currentTarget.value);
    setData({
      employees: [],
      pagination: { page: 1, pageCount: 0, pageSize: PAGE_SIZE, rowCount: 0 },
    });
    setRefresh(!refresh);
  };

  const filterStatusChange = (evt) => {
    setSelectedStatus(evt.currentTarget.value);
    setData({
      employees: [],
      pagination: { page: 1, pageCount: 0, pageSize: PAGE_SIZE, rowCount: 0 },
    });
    setRefresh(!refresh);
  };

  const searchChange = (evt) => {
    setSearch(evt.currentTarget.value);
    setData({
      employees: [],
      pagination: { page: 1, pageCount: 0, pageSize: PAGE_SIZE, rowCount: 0 },
    });
    setRefresh(!refresh);
  };

  const newEmployeeClick = () => setShowEmployeeModal(true);

  const onModalClose = () => setShowEmployeeModal(false);

  const submitEmployee = (data) => {
    setLoading(true);
    Api.postEmployee(data)
      .then(() => {
        toast.success("Employee created successfully.");
        setLoading(false);
        setRefresh(!refresh);
        setShowEmployeeModal(false);
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
      });
  };

  const onSort = (sortByValue, directionValue) => {
    setSortBy(sortByValue);
    setDirection(directionValue);
  };

  const onRefresh = () => setRefresh(!refresh);

  const { employees, pagination } = data;

  return (
    <div className="employees-list">
      <Row className="controls-container pt-1">
        <h4 className="text-left float-left employees-title">
          Employees List ({pagination.rowCount})
        </h4>
        <InputGroup className="col-12 col-md-6 col-lg-3 employees-search">
          <FormControl
            placeholder="Search"
            aria-label="Search Employee Name"
            onChange={searchChange}
            value={search}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <i className="fas fa-search"></i>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <div className="d-flex flex-grow-1 justify-content-end pr-4 pt-1">
          <Button
            onClick={() => newEmployeeClick()}
            variant="primary"
            className="bg-primary d-flex align-items-center rounded-circle"
          >
            <i className="fas fa-plus fa-sm"></i>
          </Button>
          <Button
            size="sm"
            className="rounded-circle d-flex align-items-center custom-rounded-button-refresh ml-2"
            color="primary"
            onClick={() => onRefresh()}
          >
            <RefreshCw size={18} />
          </Button>
        </div>
      </Row>
      <div className="text-left table-container col-12" role="table">
        {loading ? (
          <div className="row-container">
            <div className="flex-table text-center">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </div>
          </div>
        ) : (
          <BootstrapTable
            bootstrap4
            remote
            bordered={false}
            keyField="id"
            data={employees}
            headerClasses="bg-light"
            noDataIndication={() => "No data to show"}
            columns={[
              ...TABLE_COLUMNS(onSort),
              {
                dataField: "",
                text: "",
                sort: false,
                classes: "text-right",
                formatter: (cell, employee) => (
                  <EmployeeActions
                    employee={employee}
                    onRefresh={onRefresh}
                  />
                ),
              },
            ]}
            defaultSorted={[
              {
                dataField: sortBy,
                order: direction,
              },
            ]}
            onTableChange={() => {}}
            pagination={paginationFactory({
              page: pagination.page,
              totalSize: pagination.rowCount,
              sizePerPage: pagination.pageSize,
              sizePerPageList: [
                5,
                10,
                25,
                50,
                { text: "All", value: pagination.rowCount },
              ],
              onPageChange: (page) =>
                setData({
                  ...data,
                  pagination: {
                    ...data.pagination,
                    page: page,
                  },
                }),
              onSizePerPageChange: (sizePerPage) =>
                setData({
                  ...data,
                  pagination: {
                    ...data.pagination,
                    pageSize: sizePerPage,
                    page: 1,
                  },
                }),
            })}
          />
        )}
      </div>
      {showEmployeeModal ? (
        <EmployeeModal
          loading={loading}
          submit={submitEmployee}
          onClose={onModalClose}
        />
      ) : null}
    </div>
  );
};

export default withErrorHandler(withRouter(EmployeesList));
