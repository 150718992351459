import React, { useEffect, useState } from 'react';
import withErrorHandler from '../../error/WithErrorHandler';
import { useAuth } from '../../providers/authProvider';
import { ACTIONS, useJobDetail } from '../../providers/jobDetailsProvider';
import { getNewCrewNotes } from '../../Utils/Utils';
import './JobDetailSidebar.scss';

const NOTES_ID = 6;
const CREW_NOTES_ID = 7;
const TRANSACTION_TYPE_WITHDRAWAL = 4;

const items = [
    {
        icon: 'home',
        name: 'Home',
        id: 1
    },
    {
        icon: 'suitcase',
        name: 'Change Order',
        id: 2
    },
    {
        icon: 'clock',
        name: 'Time',
        id: 3
    },
    {
        icon: 'money-check-alt',
        name: 'Expenses',
        id: 4
    },
    {
        icon: 'users',
        name: 'Crews',
        id: 5
    },
    {
        icon: 'address-book',
        name: 'Crew Notes',
        id: 7
    },
    {
        icon: 'sticky-note',
        name: 'BO notes',
        id: NOTES_ID
    }
];

const TYPE_EXPENSE_GENERAL = 1;
const TYPE_EXPENSE_PER_DIEM = 2;
const TYPE_EXPENSE_TRAVEL = 3;

const JobDetailSidebar = () => {
    const [authContext] = useAuth();
    const [jobDetailContext, setJobDetailContext] = useJobDetail();
    const [newBackofficeNotesCount, setNewBackofficeNotesCount] = useState(0);
    const [newCrewNotesCount, setNewCrewNotesCount] = useState(0);

    useEffect(() => {
        const newBackofficeNotesCount = (jobDetailContext.job.notes || [])
            .reduce((acc, item) => {
                if (item.seen === 0 && item.creator.id !== authContext.currentUser?.id) {
                    acc++;
                };
                return acc;
            }, 0);
        setNewBackofficeNotesCount(newBackofficeNotesCount);
    }, [jobDetailContext.job, authContext.currentUser]);

    useEffect(() => {
        const newCrewNotes = getNewCrewNotes(authContext, jobDetailContext.jobDays, jobDetailContext.job);
        setNewCrewNotesCount(newCrewNotes.length);
    }, [jobDetailContext.job, jobDetailContext.jobDays, authContext]);

    const getJobExpensesByType = (type) =>
        jobDetailContext.jobDays
            .reduce(
                (p, c) =>
                    p +
                    c.transactions
                        .filter((t) => t.type === type)
                        .reduce((p, c) => p + c.amount, 0),
                0
            )
            .toFixed(2);

    const getJobExpenses = () =>
        jobDetailContext.jobDays
            .reduce(
                (p, c) =>
                    p +
                    c.transactions
                        .filter((t) => t.type !== TRANSACTION_TYPE_WITHDRAWAL)
                        .reduce((p, c) => p + c.amount, 0),
                0
            )
            .toFixed(2);

    return (
        <>
            <div className="menu-container mt-4 px-3 py-2 text-left rounded d-flex flex-column font-weight-medium">
                {items.map(i =>
                    <div
                        key={i.id}
                        onClick={() => {
                            setJobDetailContext({
                                action: ACTIONS.SET_CURRENT_SECTION,
                                payload: { section: i.id }
                            })
                        }}
                        className={`d-flex justify-content-between align-items-center menu-item ${i.id === jobDetailContext.currentSection ? 'active' : ''} cursor-pointer`}
                    >
                        <div className='d-flex align-items-center'>
                            <i className={`fas fa-${i.icon}`}></i>
                            <span>{i.name}</span>
                        </div>
                        {
                            i.id === NOTES_ID && newBackofficeNotesCount > 0 ? (
                                <div className="bg-warning d-flex rounded-circle small align-items-center justify-content-center square-20 font-weight-medium" style={{ minWidth: "auto" }}>{newBackofficeNotesCount}</div>
                            ) : null
                        }
                        {
                            i.id === CREW_NOTES_ID && newCrewNotesCount > 0 ? (
                                <div className="bg-warning d-flex rounded-circle small align-items-center justify-content-center square-20 font-weight-medium" style={{ minWidth: "auto" }}>{newCrewNotesCount}</div>
                            ) : null
                        }
                        <i className="fas fa-chevron-right float-right"></i>
                    </div>
                )}
            </div>
            <div className="sidebar-expenses mt-3">
                <div className="expenses-title">
                    <span>Job Expenses</span>
                    <span>($ {getJobExpenses()})</span>
                </div>
                <div className="expense-container">
                    <span>General Expenses</span>
                    <span className="amount">$ {getJobExpensesByType(TYPE_EXPENSE_GENERAL)}</span>
                </div>
                <div className="expense-container">
                    <span>Per Diem</span>
                    <span className="amount">$ {getJobExpensesByType(TYPE_EXPENSE_PER_DIEM)}</span>
                </div>
                <div hidden className="expense-container">
                    <span>Travel Expenses</span>
                    <span className="amount">$ {getJobExpensesByType(TYPE_EXPENSE_TRAVEL)}</span>
                </div>
            </div>
            <div className="sidebar-men-hours mt-3">
                <div className="men-hours-title">
                    <span>Men Hours</span>
                    <span>({jobDetailContext.jobDays.reduce((p, c) => p + c.workOrders.reduce((p, c) => p + c.foreman_hours + c.technician_hours, 0), 0)} hs)</span>
                </div>
                <div className="men-hours-container">
                    <span>Technicians</span>
                    <span className="amount">{jobDetailContext.jobDays.reduce((p, c) => p + c.workOrders.reduce((p, c) => p + c.technician_hours, 0), 0)} hs</span>
                </div>
                <div className="men-hours-container">
                    <span>Foremans</span>
                    <span className="amount">{jobDetailContext.jobDays.reduce((p, c) => p + c.workOrders.reduce((p, c) => p + c.foreman_hours, 0), 0)} hs</span>
                </div>
            </div>
        </>
    );
};

export default withErrorHandler(JobDetailSidebar);