import React from "react";
import withErrorHandler from "../error/WithErrorHandler";
import { Modal, Button, Spinner } from "react-bootstrap";

const ConfirmationModal = ({
  title,
  body,
  variant = "primary",
  onClose,
  submit,
  loading,
}) => {

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-between align-items-center">
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button disabled={loading} variant={variant} onClick={submit}>
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Confirm"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withErrorHandler(ConfirmationModal);
