import React, { useState } from 'react';
import withErrorHandler from '../../../error/WithErrorHandler';
import './JobDetailChangeOrderSection.scss';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useJobDetail } from '../../../providers/jobDetailsProvider';

const JobDetailChangeOrderSection = () => {
    const [jobDetailContext] = useJobDetail();
    const [showDetailModal, setShowDetailModal] = useState();
    const [changeOrder, setChangeOrder] = useState({});

    const handleDetailModalClose = () => {
        setShowDetailModal();
        setChangeOrder({});
    }

    const changeOrders = jobDetailContext.jobDays.reduce((p, c) => [...p, ...c.workOrders.map(wo => Object.assign(wo, { date: c.date }))], []);

    return (
        <>
            {changeOrders.length ? (
                <>
                    {changeOrders.map(changeOrder =>
                        <div
                            onClick={() => {
                                setShowDetailModal(true);
                                setChangeOrder(changeOrder);
                            }}
                            key={changeOrder.id}
                            className="col-12 flex-table row change-order-row"
                            role="rowgroup"
                        >
                            <div className="flex-row" role="cell">
                                <i className="fas fa-suitcase"></i>
                                <span className="wo-date">{moment(changeOrder.date).format('MMMM DD - YYYY')}</span>
                                <span className="wo-creator">({changeOrder.creator ? `${changeOrder.creator.firstName} ${changeOrder.creator.lastName}` : '-'})</span>
                            </div>
                            <span className="wo-hours">{changeOrder.foreman_hours + changeOrder.technician_hours} hs</span>
                        </div>
                    )}
                    <div className="mt-3 text-right">
                        <span>Total extra:</span>
                        <span className="text-primary font-weight-medium total-hours">{changeOrders.reduce((p, c) => p + c.foreman_hours + c.technician_hours, 0)} hs</span>
                    </div>
                </>
            ) : (
                <div className="flex-table row no-data-row" role="rowgroup">
                    <div>No work orders recorded</div>
                </div>
            )}
            <Modal className="change-order-modal" show={showDetailModal} onHide={handleDetailModalClose}>
                <Modal.Header className="font-weight-medium" closeButton>Change Order Detail</Modal.Header>
                <Modal.Body>
                    <>
                        <p>{changeOrder.description}</p>
                        <p>
                            <span className="wo-date">{moment(changeOrder.date).format('MMMM DD - YYYY')}</span>
                            <span className="wo-creator">({changeOrder.creator ? `${changeOrder.creator.firstName} ${changeOrder.creator.lastName}` : '-'})</span>
                        </p>
                        <div className="employee-hours-container">
                            <div className="employee-hours">
                                <div className="col-12 p-0 employee-hour-container overflow-hidden">
                                    <span className="col-7 dark-bg-primary">Foremen: </span>
                                    <span className="col-5 bg-primary text-center">
                                        <i className="fas fa-clock"></i>
                                        {`${changeOrder.foreman_hours ? changeOrder.foreman_hours : '0'} hs`}
                                    </span>
                                </div>
                            </div>
                            <div className="employee-hours mt-2">
                                <div className="col-12 p-0 employee-hour-container overflow-hidden">
                                    <span className="col-7 dark-bg-primary">Foremen Overtime: </span>
                                    <span className="col-5 bg-primary text-center">
                                        <i className="fas fa-clock"></i>
                                        {`${changeOrder.foreman_overtime_hours ? changeOrder.foreman_overtime_hours : '0'} hs`}
                                    </span>
                                </div>
                            </div>
                            <div className="mt-3 employee-hours">
                                <div className="col-12 p-0 employee-hour-container overflow-hidden">
                                    <span className="col-7 dark-bg-primary">Technicians: </span>
                                    <span className="col-5 bg-primary text-center">
                                        <i className="fas fa-clock"></i>
                                        {`${changeOrder.technician_hours ? changeOrder.technician_hours : '0'} hs`}
                                    </span>
                                </div>
                            </div>
                            <div className="employee-hours mt-2">
                                <div className="col-12 p-0 employee-hour-container overflow-hidden">
                                    <span className="col-7 dark-bg-primary">Technicians Overtime: </span>
                                    <span className="col-5 bg-primary text-center">
                                        <i className="fas fa-clock"></i>
                                        {`${changeOrder.technician_overtime_hours ? changeOrder.technician_overtime_hours : '0'} hs`}
                                    </span>
                                </div>
                            </div>
                            <div className="mt-3 employee-hours">
                                <div className="col-12 p-0 employee-hour-container overflow-hidden">
                                    <span className="col-7 dark-bg-primary">Management: </span>
                                    <span className="col-5 bg-primary text-center">
                                        <i className="fas fa-clock"></i>
                                        {`${changeOrder.management_hours ? changeOrder.management_hours : '0'} hs`}
                                    </span>
                                </div>
                            </div>
                            <div className="employee-hours mt-2">
                                <div className="col-12 p-0 employee-hour-container overflow-hidden">
                                    <span className="col-7 dark-bg-primary">Management Overtime: </span>
                                    <span className="col-5 bg-primary text-center">
                                        <i className="fas fa-clock"></i>
                                        {`${changeOrder.management_overtime_hours ? changeOrder.management_overtime_hours : '0'} hs`}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <p>
                            <span>Parts / Material:</span>
                            {changeOrder.parts_material ? (
                                <span className='ml-1'>{changeOrder.parts_material}</span>
                            ) : (
                                <span className='ml-1 text-muted'>Not provided</span>
                            )}
                        </p>
                        <p>
                            Client Signature:
                            {changeOrder.signature_url ? (
                                <p>
                                    <img alt={'signature'} className="client-signature" src={changeOrder.signature_url} />
                                    <small className="float-right">{changeOrder.signature_name}</small>
                                </p>
                            ) : (
                                <span className='ml-1 text-muted'>Not provided</span>
                            )}
                        </p>
                    </>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default withErrorHandler(JobDetailChangeOrderSection);