import axios from 'axios';
import config from '../Config';

const handleError = err => {
    if (!err.response) {
        if (window.location.href.indexOf('login') < 0) { window.location.assign('login') };
    } else if (err.response.status === 401) {
        if (window.location.href.indexOf('login') < 0) { window.location.assign('login') };
        throw (err);
    } else {
        throw err;
    }
}

const Api = {
    login(data) {
        return axios('/api/auth/back/login',
            {
                baseURL: config.apiURL,
                method: 'post',
                data: { email: data.email, password: data.password },
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },
    logout() {
        return axios(`/api/auth/back/logout`, {
            baseURL: config.apiURL,
            withCredentials: true,
            method: 'post',
        }).then(result => result.data)
    },
    validate() {
        return axios(`/api/auth/back/validate`,
            {
                baseURL: config.apiURL,
                withCredentials: true,
                method: 'get',
            })
            .then(result => result.data)
            .catch(err => handleError(err));
    },
    getPayrollData(start_date, end_date, categoryId) {
        const params = categoryId
            ? `start_date=${start_date}&end_date=${end_date}&category_id=${categoryId}`
            : `start_date=${start_date}&end_date=${end_date}`
        return axios(`/api/payrolls?${params}`,
            {
                baseURL: config.apiURL,
                method: 'get',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },
    getPostContractPDF(id) {
        return axios(`/api/post-contracts/pdf/${id}`,
            {
                baseURL: config.apiURL,
                method: 'get',
                responseType: "blob",
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    proxyFile(url) {
        return axios(`/api/storage-pdfs/proxy-file`,
            {
                baseURL: config.apiURL,
                method: 'post',
                responseType: "blob",
                withCredentials: true,
                data: { url: url }
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    getJobs(data = {}) {
        const query = Object.keys(data).filter(d => data[d]).map(d => `${d}=${data[d]}`).join('&');
        return axios(data.id ? `/api/jobs/${data.id}` : `/api/jobs?${query}`,
            {
                baseURL: config.apiURL,
                method: 'get',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    getJobDays(job_id) {
        return axios(`/api/job-days?job_id=${job_id}`,
            {
                baseURL: config.apiURL,
                method: 'get',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    postJob(job) {
        return axios('/api/jobs',
            {
                baseURL: config.apiURL,
                method: 'post',
                withCredentials: true,
                data: job
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    putJob(job) {
        return axios(`/api/jobs/${job.id}`,
            {
                baseURL: config.apiURL,
                method: 'put',
                withCredentials: true,
                data: job
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    deleteJob(id) {
        return axios(`/api/jobs/${id}`,
            {
                baseURL: config.apiURL,
                method: 'delete',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    postJobNote(note) {
        return axios('/api/jobs-notes',
            {
                baseURL: config.apiURL,
                method: 'post',
                withCredentials: true,
                data: note
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    deleteJobNote(id) {
        return axios(`/api/jobs-notes/${id}`,
            {
                baseURL: config.apiURL,
                method: 'delete',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    putJobNote(note) {
        return axios(`/api/jobs-notes/${note.id}`,
            {
                baseURL: config.apiURL,
                method: 'put',
                withCredentials: true,
                data: note
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    getEmployees(data = {}) {
        const query = Object.keys(data).filter(d => data[d]).map(d => `${d}=${data[d]}`).join('&');
        return axios(data.id ? `/api/employees/${data.id}` : `/api/employees?${query}`,
            {
                baseURL: config.apiURL,
                method: 'get',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    postEmployee(employee) {
        return axios('/api/employees',
            {
                baseURL: config.apiURL,
                method: 'post',
                withCredentials: true,
                data: employee
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    putEmployee(employee) {
        return axios(`/api/employees/${employee.id}`,
            {
                baseURL: config.apiURL,
                method: 'put',
                withCredentials: true,
                data: employee
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    deleteEmployee(id) {
        return axios(`/api/employees/${id}`,
            {
                baseURL: config.apiURL,
                method: 'delete',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    getCrews() {
        return axios('/api/crews',
            {
                baseURL: config.apiURL,
                method: 'get',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    getCrew(id) {
        return axios(`/api/crews/${id}`,
            {
                baseURL: config.apiURL,
                method: 'get',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    postCrew(crew) {
        return axios('/api/crews',
            {
                baseURL: config.apiURL,
                method: 'post',
                withCredentials: true,
                data: crew
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    putCrew(crew) {
        return axios(`/api/crews/${crew.id}`,
            {
                baseURL: config.apiURL,
                method: 'put',
                withCredentials: true,
                data: crew
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    deleteCrew(id) {
        return axios(`/api/crews/${id}`,
            {
                baseURL: config.apiURL,
                method: 'delete',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    postEmployeeCrew(data) {
        return axios('/api/employee-crews',
            {
                baseURL: config.apiURL,
                method: 'post',
                withCredentials: true,
                data
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    deleteEmployeeCrew(id) {
        return axios(`/api/employee-crews/${id}`,
            {
                baseURL: config.apiURL,
                method: 'delete',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    postClockEntries(data) {
        return axios('/api/clock-entries',
            {
                baseURL: config.apiURL,
                method: 'post',
                withCredentials: true,
                data: data
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    postTrips(data) {
        return axios('/api/trips',
            {
                baseURL: config.apiURL,
                method: 'post',
                withCredentials: true,
                data: data
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    postBonus(data) {
        return axios('/api/bonus-times',
            {
                baseURL: config.apiURL,
                method: 'post',
                withCredentials: true,
                data: data
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    putBonus(data) {
        return axios(`/api/bonus-times/${data.id}`,
            {
                baseURL: config.apiURL,
                method: 'put',
                withCredentials: true,
                data: data
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    getCustomers(data = {}) {
        const query = Object.keys(data).filter(d => data[d]).map(d => `${d}=${data[d]}`).join('&');
        return axios(data.id ? `/api/customers/${data.id}` : `/api/customers?${query}`,
            {
                baseURL: config.apiURL,
                method: 'get',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    postCustomer(customer) {
        return axios('/api/customers',
            {
                baseURL: config.apiURL,
                method: 'post',
                withCredentials: true,
                data: customer
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    putCustomer(customer) {
        return axios(`/api/customers/${customer.id}`,
            {
                baseURL: config.apiURL,
                method: 'put',
                withCredentials: true,
                data: customer
            })
            .then(result => result)
            .catch(err => handleError(err));
    },

    deleteCustomer(id) {
        return axios(`/api/customers/${id}`,
            {
                baseURL: config.apiURL,
                method: 'delete',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },
    signDocuments: (data) => {
        return axios(`/api/aws/sign`, {
            baseURL: config.apiURL,
            withCredentials: true,
            method: 'post',
            data
        })
            .then((result) => result.data)
            .catch((err) => handleError(err));
    },
    putDocumentsToS3: (data) => {
        return axios(data.url, {
            method: 'put',
            data: data.file,
            headers: {
                'Content-Type': data.type
            }
        })
            .then(() => {
                return { name: data.file.name, url: data.fileUrl };
            })
            .catch((err) => handleError(err));
    },
    getExpensesReport(data = {}) {
        const query = Object.keys(data).filter(d => data[d]).map(d => `${d}=${data[d]}`).join('&');
        return axios(`/api/reports/expenses?${query}`,
            {
                baseURL: config.apiURL,
                method: 'get',
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },
    getExpensesReportPDF(data = {}) {
        const query = Object.keys(data).filter(d => data[d]).map(d => `${d}=${data[d]}`).join('&');
        return axios(`/api/reports/expenses-pdf?${query}`,
            {
                baseURL: config.apiURL,
                method: 'get',
                responseType: "blob",
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },
    getJobPackageReportPDF(data = {}) {
        const query = Object.keys(data).filter(d => data[d]).map(d => `${d}=${data[d]}`).join('&');
        return axios(`/api/jobs/job-package-pdf?${query}`,
            {
                baseURL: config.apiURL,
                method: 'get',
                responseType: "blob",
                withCredentials: true
            })
            .then(result => result)
            .catch(err => handleError(err));
    },
}

export default Api;