import React from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import withErrorHandler from "../error/WithErrorHandler";
import { getAllCrew, getTimeAsHours } from "../Utils/Utils";

const JobsPayrollSection = ({ jobs, employees, payroll_data, onClick }) => {
  const history = useHistory();

  const goJobDetails = (jobId) => {
    history.push(`/jobs/${jobId}`);
  };

  const renderJobs = () => {
    return jobs
      .sort((x, y) => x.id - y.id)
      .map((j) => (
        <span
          key={j.id}
          onClick={() => goJobDetails(j.id)}
          className="cursor-pointer flex-row th employee-job-hours-link text-primary"
          role="columnheader"
        >
          {`${j.so_number ? j.so_number + " / " : ""}${j.location}`}
        </span>
      ));
  };

  const isEmployeeAssignedToJob = (employee, job) => {
    const allCrew = getAllCrew(job);
    const isAssigned = allCrew.find((e) => e.id === employee.id);
    return isAssigned;
  };

  const getJobTimes = (e, id) => {
    let payroll = employeePayroll(e)
      ? employeePayroll(e).job_hours.find((p) => p.job_id === id)
      : null;
    return payroll
      ? {
          clock_time: payroll.times.reduce((p, c) => {
            if (c.lunch_break > 0) {
              p = p - c.lunch_break;
            }
            return p + c.time;
          }, 0),
          travel_time: payroll.times.reduce((p, c) => p + c.travel_time, 0),
        }
      : { clock_time: 0, travel_time: 0 };
  };

  const employeeJobsHours = (e) => {
    return jobs
      .sort((x, y) => x.id - y.id)
      .map((j) => {
        const jobTimes = getJobTimes(e, j.id);
        const isAssigned = isEmployeeAssignedToJob(e, j);
        return (
          <div
            key={j.id}
            className="flex-row editable employee-job-hours"
            role="columnheader"
          >
            {isAssigned ? (
              <>
                <span onClick={() => onClick(e.id)} className="clickable">
                  <i className="far fa-clock"></i>
                  {getTimeAsHours(jobTimes.clock_time)}
                </span>
                {jobTimes.travel_time > 0 ? (
                  <span onClick={() => onClick(e.id)} className="clickable">
                    <i className="fas fa-car"></i>
                    {getTimeAsHours(jobTimes.travel_time)}
                  </span>
                ) : (
                  <></>
                )}
              </>
            ) : (
              "-"
            )}
          </div>
        );
      });
  };

  const employeePayroll = (e) => payroll_data.find((p) => p.employee.id === e.id);

  if (!jobs) {
    return <></>;
  }
  return (
    <div className="payroll-jobs p-0">
      <div className="flex-table header nowrap" role="rowgroup">
        {renderJobs()}
      </div>
      {employees
        .sort((x, y) => (x.firstName < y.firstName ? -1 : 1))
        .map((e, i) => (
          <div key={i} className="flex-table row nowrap" role="rowgroup">
            {employeeJobsHours(e)}
          </div>
        ))}
    </div>
  );
};

export default withErrorHandler(withRouter(JobsPayrollSection));
