import moment from "moment";
import React, { useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import { Modal, Button } from "react-bootstrap";

import "react-day-picker/lib/style.css";

import DateRangeLabel from "./DateRangeLabel";

const numberOfMonths = 1;

const currentDate = new Date();

const DateRangePicker = ({
  startDate,
  endDate,
  originalStartDate,
  originalEndDate,
  disabledBefore,
  disabledAfter,
  doSubmit,
}) => {
  const [from, setFrom] = useState(startDate ? new Date(startDate) : null);
  const [to, setTo] = useState(new Date(endDate));
  const [openPicker, setOpenPicker] = useState(false);

  const [month, setMonth] = useState(from || to || currentDate);

  const handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, { from, to });
    if (!range || (!range.from && !range.to)) {
      return;
    }
    setFrom(range.from);
    setTo(range.to);
  };

  const modifiers = { start: from, end: to };

  const disabledDays = {};
  if (disabledBefore) {
    disabledDays.before = new Date(
      moment(disabledBefore).startOf("day").format()
    );
  }
  if (disabledAfter) {
    disabledDays.after = new Date(moment(disabledAfter).endOf("day").format());
  }

  return (
    <div className="text-center">
      <div className="no-margin text-left overflow-hidden">
        {!from && !to && (
          <p className="float-left">Please select the first day.</p>
        )}
        {from && !to && (
          <p className="float-left">Please select the last day.</p>
        )}
        {from && to && (
          <div className="d-flex align-items-center">
            <DateRangeLabel
              startDate={originalStartDate}
              endDate={originalEndDate}
            />
            <Button
              variant="primary"
              className="btn-sm calendar-btn bg-primary"
              onClick={() => setOpenPicker(true)}
            >
              <i className="far fa-calendar-alt"></i>
            </Button>
          </div>
        )}
      </div>
      <Modal
        className="text-center date-modal"
        show={openPicker}
        onHide={() => setOpenPicker(false)}
      >
        <Modal.Body>
          <div className="week-calendar">
            <DayPicker
              className="date-range-picker bg-white rounded border mb-3"
              numberOfMonths={numberOfMonths}
              month={month}
              onMonthChange={setMonth}
              selectedDays={[from, { from, to }]}
              disabledDays={disabledDays}
              modifiers={modifiers}
              onDayClick={handleDayClick}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-between">
          <Button
            variant="secondary"
            onClick={() => {
              setFrom(startDate ? new Date(startDate) : null);
              setTo(new Date(endDate));
              setMonth(new Date(originalStartDate));
              setOpenPicker(false);
            }}
          >
            Discard
          </Button>
          <Button
            variant="info"
            onClick={() => setMonth(new Date())}
          >
            Today
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              doSubmit(from, to);
              setOpenPicker(false);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DateRangePicker;
