import React, { useEffect, useState } from 'react';
import withErrorHandler from '../../../error/WithErrorHandler';
import { Row } from 'react-bootstrap';
import moment from 'moment';
import './JobDetailTimeEmployeesReport.scss';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { getAllCrew, getTimeAsHours } from '../../../Utils/Utils';
import { ACTIONS, useJobDetail } from '../../../providers/jobDetailsProvider';

const ACCORDION_KEY = 'jobDetailTimeEmployeesAccordion';

const JobDetailTimeEmployeesReport = () => {
    const [jobDetailContext, setJobDetailContext] = useJobDetail();

    const [allCrew, setAllCrew] = useState([]);

    useEffect(() => {
        const allCrew = getAllCrew(jobDetailContext.job);
        setAllCrew(allCrew);
    }, [jobDetailContext.job]);

    return (
        jobDetailContext.jobDays.length ? (
            <Accordion
                className="job-detail-employees-time-report"
                allowZeroExpanded={true}
                allowMultipleExpanded={true}
                onChange={(preExpanded) => {
                    setJobDetailContext({
                        action: ACTIONS.TOGGLE_ACCORDION,
                        payload: {
                            accordion: ACCORDION_KEY,
                            preExpanded
                        }
                    })
                }}
                preExpanded={jobDetailContext.jobDays.map(jd => jd.id)}
            >
                {jobDetailContext.jobDays.map((jd, k) =>
                    <AccordionItem key={jd.id} uuid={jd.id}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <span>{moment(jd.date).format('MMMM DD - YYYY')}</span>
                                <i className="fas fa-chevron-up float-right"></i>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {allCrew.map((e, i) => {
                                const ed = jd.employeeDays.find(ed => ed.employee_id === e.id);
                                return (
                                    <Row key={`${i}-${k}`} className="employee-row">
                                        <span>{`${e.name}`}</span>
                                        <div className="flex-row" role="cell">
                                            {ed ? (
                                                <>
                                                    <span className="time-container" key={`${jd.id}-${e.id}-cl`}>
                                                        <i className="far fa-clock"></i>
                                                        {getTimeAsHours(ed.clockEntries.reduce((p, c) => p + c.duration, 0))}
                                                    </span>
                                                    <span className="time-container" key={`${jd.id}-${e.id}-tr`}>
                                                        <i className="fas fa-car"></i>
                                                        {getTimeAsHours(ed.trips.reduce((p, c) => p + c.duration, 0))}
                                                    </span>
                                                </>
                                            ) : '-'}
                                        </div>
                                    </Row>
                                )
                            })}
                        </AccordionItemPanel>
                    </AccordionItem>
                )}
            </Accordion>
        ) : (
            <div className="flex-table row no-data-row" role="rowgroup">
                <div className="flex-row" role="cell">No working time recorded</div>
            </div>
        )
    );
};

export default withErrorHandler(JobDetailTimeEmployeesReport);