import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const DateRangeLabel = ({ title, startDate, endDate }) => {
  return (
    <div className="d-flex mx-2 align-items-center">
        <div className="d-flex mx-2 align-items-center p-2 border rounded bg-white">
            <FontAwesomeIcon icon={faCalendar} size="1x" className="mr-2 text-secondary" style={{ paddingBottom: "1px" }}/>
            <span>{moment(startDate).format("MM/DD/YYYY")}</span>
        </div>
        <span>to</span>
        <div className="d-flex mx-2 align-items-center p-2 border rounded bg-white">
            <FontAwesomeIcon icon={faCalendar} size="1x" className="mr-2 text-secondary" style={{ paddingBottom: "1px" }}/>
            <span>{moment(endDate).format("MM/DD/YYYY")}</span>
        </div>
    </div>
  );
};

export default DateRangeLabel;