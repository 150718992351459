import React, { useEffect, useState } from "react";
import withErrorHandler from "../error/WithErrorHandler";
import { Modal, Button } from "react-bootstrap";
import "./DateControls.css";
import moment from "moment";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import DateRangeLabel from "../Expenses/DateRangeLabel";

const getWeekDays = (weekStart) => {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, "days").toDate());
  }
  return days;
};

const getWeekRange = (date) => {
  return {
    from: moment(date).startOf("isoWeek").toDate(),
    to: moment(date).endOf("isoWeek").toDate(),
  };
};

const DateControls = ({
  startDate,
  dateSelection,
  originalStartDate,
  doSubmit,
}) => {
  const [show, setShow] = useState(false);
  const [hoverRange, setHoverRange] = useState(undefined);
  const [selectedDays, setSelectedDays] = useState(
    getWeekDays(getWeekRange(startDate || new Date()).from)
  );
  const [month, setMonth] = useState(
    getWeekDays(getWeekRange(startDate || new Date()).from)[0]
  );

  useEffect(() => {
    if (dateSelection) {
      dateSelection(selectedDays[0], selectedDays[6])
    }
  }, [selectedDays, dateSelection]);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleDayChange = (date) => {
    setSelectedDays(getWeekDays(getWeekRange(date).from));
  };

  const handleDayEnter = (date) => {
    setHoverRange(getWeekRange(date));
  };

  const handleDayLeave = () => {
    setHoverRange(undefined);
  };

  const handleWeekClick = (weekNumber, days, e) => {
    setSelectedDays(days);
  };

  const daysAreSelected = selectedDays.length > 0;

  const modifiers = {
    hoverRange,
    selectedRange: daysAreSelected && {
      from: selectedDays[0],
      to: selectedDays[6],
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart: daysAreSelected && selectedDays[0],
    selectedRangeEnd: daysAreSelected && selectedDays[6],
  };

  return (
    <div className="text-center">
      <div className="no-margin text-left overflow-hidden">
        <div className="d-flex align-items-center">
          <DateRangeLabel
            startDate={moment(originalStartDate).startOf("isoWeek").toDate()}
            endDate={moment(originalStartDate).endOf("isoWeek").toDate()}
          />
          <Button
            variant="primary"
            className="btn-sm calendar-btn bg-primary"
            onClick={handleShow}
          >
            <i className="far fa-calendar-alt"></i>
          </Button>
        </div>
      </div>
      <Modal
        className="text-center date-modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="week-calendar">
            <DayPicker
              className="bg-white rounded border mb-3"
              selectedDays={selectedDays}
              month={month}
              onMonthChange={(m) => setMonth(m)}
              showOutsideDays
              modifiers={modifiers}
              firstDayOfWeek={1}
              onDayClick={handleDayChange}
              onDayMouseEnter={handleDayEnter}
              onDayMouseLeave={handleDayLeave}
              onWeekClick={handleWeekClick}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-between">
          <Button
            variant="secondary"
            onClick={() => {
              setHoverRange(undefined);
              setSelectedDays(
                getWeekDays(getWeekRange(startDate || new Date()).from)
              );
              setMonth(
                getWeekDays(getWeekRange(startDate || new Date()).from)[0]
              );
              handleClose();
            }}
          >
            Discard
          </Button>
          <Button variant="info" onClick={() => setMonth(new Date())}>
            Today
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              doSubmit(selectedDays[0], selectedDays[6]);
              handleClose();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withErrorHandler(DateControls);
