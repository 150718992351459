import React, { useEffect, useState } from "react";
import "./JobsCalendar.scss";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import withErrorHandler from "../error/WithErrorHandler";
import moment from "moment";
import Api from "../api/Api";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useCallback } from "react";

const TENTATIVE = "0";

const ForemansColors = [
  "#468AE8",
  "#9945E8",
  "#E84663",
  "#46B9E8",
  "#E8B245",
  "#65B058",
  "#58A4AF",
  "#AF5858",
  "#151965",
  "#32407b",
  "#515585",
  "#46b5d1",
  "#44000d",
  "#83142c",
  "#ad1d45",
  "#f9d276",
];

const JobsCalendar = ({ search, setTotalJobs }) => {
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [view, ] = useState("dayGridMonth");

  useEffect(() => {
    Api.getJobs()
      .then((result) => {
        setLoading(false);
        setJobs(result.data.jobs);
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
      });
  }, []);

  const fetchJobs = useCallback(() => {
    setLoading(true);
    Api.getJobs({
      search,
    })
      .then((result) => {
        setLoading(false);
        setJobs(result.data.jobs);
        if (setTotalJobs) {
          setTotalJobs(result.data.pagination.rowCount);
        }
      })
      .catch((err) => {
        const msg = err.response?.data?.length
          ? err.response.data[0].msg
          : "There was an error with your request";
        toast.error(msg);
        setLoading(false);
      });
  }, [search, setTotalJobs]);

  useEffect(() => {
    fetchJobs();
  }, [search, fetchJobs]);

  const getForemans = (job) =>
    job.crews
      .filter((crew) => crew.lead)
      .flatMap((crew) =>
        crew.foremans.map((c) => ({
          name: `${c.employee.firstName} ${c.employee.lastName}`,
          number: c.employee.number,
          isLead: c.is_lead,
        }))
      );

  const getForemanNameJob = (id) => {
    const job = jobs.find((j) => j.id === id);
    const foremans = getForemans(job);
    const foreman = foremans.find((f) => f.isLead);
    return foreman ? `${foreman.name}` : "No Foreman";
  };

  const getForemanNumberByJob = (id) => {
    const job = jobs.find((j) => j.id === id);
    const foremans = getForemans(job);
    const foreman = foremans.find((f) => f.isLead);
    return foreman ? foreman.number : "No Foreman";
  };

  const getCustomerNameJob = (id) => jobs.find((j) => j.id === id).customer.name;

  const isJobTentative = (id) => jobs.find((j) => j.id === id).status === TENTATIVE;

  const getJobs = () =>
    jobs.map((j) => {
      const start = moment(j.start_date).startOf("day").toDate();
      const end = moment(j.end_date).add(1, "day").endOf("day").toDate();
      const title = `${j.so_number ? j.so_number + " / " : ""}${j.location}`;
      return {
        id: j.id,
        start,
        end,
        title,
      };
    });

  if (!jobs) {
    return <></>;
  }
  return (
    <div className="col-12 jobs-calendar-container">
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <FullCalendar
          header={{
            left: "today",
            center: "prev, title, next",
            right: "dayGridMonth, dayGridWeek",
          }}
          height="auto"
          firstDay={1}
          locale="en"
          defaultView={view}
          plugins={[dayGridPlugin]}
          columnHeaderFormat={{ weekday: "long" }}
          allDayDefault={true}
          eventTextColor="white"
          events={getJobs()}
          buttonText={{
            today: "Today",
            dayGridMonth: "month view",
            dayGridWeek: "week view",
          }}
          eventRender={(event) => {
            const tentative = isJobTentative(event.event.id)
              ? '<span class="float-left tentative_badge">Tentative</span>'
              : "";
            event.el.innerHTML = `
                                    <span class="float-left">${
                                      event.event.number
                                        ? `<span class="font-weight-medium">#J${event.event.number}</span>`
                                        : ""
                                    } ${
              event.event.title
            } | ${getCustomerNameJob(event.event.id)}</span>
                                    ${tentative}
                                    <span class="float-right">${getForemanNameJob(
                                      event.event.id
                                    )}</span>
                                `;
            const f_number = getForemanNumberByJob(event.event.id);
            event.el.style.background =
              ForemansColors[f_number % ForemansColors.length];
          }}
        />
      )}
    </div>
  );
};

export default withErrorHandler(JobsCalendar);
