const api_dev = 'https://faulkner-api.dev.belovedrobot.com/';
const api_prod = 'https://faulkner-api.belovedrobot.com/';
const api_local = 'http://localhost:3001';
let apiURL, documentsBucket;

if (process.env.REACT_APP_ENV === 'production') {
    apiURL = api_prod;
    documentsBucket = "faulkner-api-prod"
} else if (process.env.REACT_APP_ENV === 'development') {
    apiURL = api_dev;
    documentsBucket = "faulkner-api-dev"
} else {
    apiURL = api_local;
    documentsBucket = "faulkner-api-dev"
}

const config = {
    apiURL,
    documentsBucket
}

export default config;