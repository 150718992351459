import React from "react";
import "./JobDetail.scss";
import withErrorHandler from "../../error/WithErrorHandler";
import { useJobDetail } from "../../providers/jobDetailsProvider";
import moment from "moment";

const TRANSACTION_TYPE_WITHDRAWAL = 4;

const JobInfo = () => {
  const [jobDetailContext] = useJobDetail();

  const getJobDates = () => {
    const { start_date, end_date } = jobDetailContext.job;
    const sd = moment(start_date);
    const validStartDate = start_date && moment(start_date).isValid();
    const validEndDate = end_date && moment(end_date).isValid();
    if (!validStartDate) {
      return "Tentative";
    } else if (!validEndDate) {
      return `${sd.format("MMMM").slice(0, 3)}, ${sd.format(
        "DD"
      )} - End date not defined`;
    };
    const ed = moment(end_date);
    return sd.get("month") === ed.get("month")
      ? `${sd.format("DD")} - ${ed.format("DD")}, ${sd
        .format("MMMM")
        .slice(0, 3)}`
      : `${sd.format("MMMM").slice(0, 3)}, ${sd.format("DD")} - ${ed
        .format("MMMM")
        .slice(0, 3)}, ${ed.format("DD")}`;
  };

  const getJobExpenses = () =>
    jobDetailContext.jobDays
      .reduce(
        (p, c) =>
          p +
          c.transactions
            .filter((t) => t.type !== TRANSACTION_TYPE_WITHDRAWAL)
            .reduce((p, c) => p + c.amount, 0),
        0
      )
      .toFixed(2);

  return (
    <div className="col-12 other-info px-0 m-0">
      <div className="inline-flex col-12 bg-primary">
        <span className="col-6 btn font-weight-medium">
          <i className="far fa-calendar-alt"></i>
          {getJobDates()}
        </span>
        <span className="col-6 btn font-weight-medium">
          <i className="fas fa-wallet"></i>$ {getJobExpenses()}
        </span>
      </div>
      <div className="col-12 text-left p-0 location font-weight-medium">
        <span>Full Address:</span>
        <span className="lighter-color">
          {" "}
          {[
            jobDetailContext.job.location,
            jobDetailContext.job.city,
            jobDetailContext.job.state,
            jobDetailContext.job.zipcode,
          ]
            .filter(Boolean)
            .join(", ")}{" "}
          <i className="fas fa-map-marker-alt text-primary fa-lg ml-2"></i>
        </span>
      </div>
      {jobDetailContext.job.mileage ? (
        <div className="col-12 text-left p-0 distance font-weight-medium">
          <span>Distance:</span>
          <span className="lighter-color">
            {" "}
            +/- {jobDetailContext.job.mileage} miles
          </span>
        </div>
      ) : (
        <></>
      )}
      {jobDetailContext.job.inbound_mileage ? (
        <div className="col-12 text-left p-0 distance font-weight-medium">
          <span>Inbound Mileage:</span>
          <span className="lighter-color">
            {" "}
            {jobDetailContext.job.inbound_mileage} miles
          </span>
        </div>
      ) : (
        <></>
      )}
      {jobDetailContext.job.outbound_mileage ? (
        <div className="col-12 text-left p-0 distance font-weight-medium">
          <span>Outbound Mileage:</span>
          <span className="lighter-color">
            {" "}
            {jobDetailContext.job.outbound_mileage} miles
          </span>
        </div>
      ) : (
        <></>
      )}
      {jobDetailContext.job.inbound_flight ? (
        <div className="col-12 text-left p-0 distance font-weight-medium">
          <span>Inbound Flight:</span>
          <span className="lighter-color">
            {" "}
            {jobDetailContext.job.inbound_flight}
          </span>
        </div>
      ) : (
        <></>
      )}
      {jobDetailContext.job.outbound_flight ? (
        <div className="col-12 text-left p-0 distance font-weight-medium">
          <span>Outbound Flight:</span>
          <span className="lighter-color">
            {" "}
            {jobDetailContext.job.outbound_flight}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default withErrorHandler(JobInfo);
