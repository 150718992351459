import React, { useCallback, useEffect, useState } from "react";
import "./Payroll.scss";
import Api from "../api/Api";
import withErrorHandler from "../error/WithErrorHandler";
import EmployeePayrollSection from "./EmployeePayrollSection";
import DateControls from "./DateControls";
import { Button, Row, Spinner, Form } from "react-bootstrap";
import moment from "moment";
import JobsPayrollSection from "./JobsPayrollSection";
import TimesPayrollSection from "./TimesPayrollSection";
import EmployeePayrollModal from "./employeePayrollModal/EmployeePayrollModal";
import { withRouter } from "react-router-dom";
import { payrollToPDF } from "../Utils/Utils";
import { EMPLOYEE_CATEGORIES } from "../constants";
import { toast } from "react-toastify";
import { ACTIONS, usePayroll } from "../providers/payrollProvider";

const SCROLL_STEP = 120;
const CATEGORY_INSURED = "1";
const CATEGORY_ALL = "All";

const Payroll = () => {
  const [payrollContext, setPayrollContext] = usePayroll();

  const [loading, setLoading] = useState(false);
  const [payrollData, setPayrollData] = useState(null);
  const [currentWeekPayrollData, setCurrentWeekPayrollData] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().startOf("isoWeek").toDate()
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("isoWeek").toDate()
  );

  const [originalStartDate, setOriginalStartDate] = useState(
    moment().startOf("isoWeek").toDate()
  );

  const [showEmployeePayrollModal, setShowEmployeePayrollModal] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [refresh, setRefresh] = useState();

  const dateSelection = (start_date, end_date) => {
    setStartDate(start_date);
    setEndDate(end_date);
  };

  const employeePayrollClick = (employee) => {
    const employeePayrollData = payrollData.find((p) => p.employee.id === employee);
    const jobs = currentWeekPayrollData.jobs_with_registered_time_this_week;
    setPayrollContext({
      action: ACTIONS.INIT,
      payload: { payrollData: employeePayrollData, jobs, }
    });
    setShowEmployeePayrollModal(true);
  };

  const submitEmployeePayroll = async () => {
    try {
      const { touchedClocks, touchedTrips, bonus } = payrollContext;
      const hasError = touchedClocks.find(e => e.hasClockError) ||
        touchedTrips.find(e => e.hasClockError) ||
        touchedClocks.find(e => e.hasLunchError) ||
        touchedTrips.find(e => e.hasLunchError);
      if (hasError) {
        return toast.error("Please fix the errors before saving");
      };
      setLoading(true);
      if (touchedClocks?.length) {
        await Api.postClockEntries({ clockEntries: touchedClocks });
      };
      if (touchedTrips?.length) {
        await Api.postTrips({ trips: touchedTrips });
      };
      if (bonus) {
        if (bonus.id) {
          await Api.putBonus(bonus);
        } else {
          bonus.week_number = moment(startDate).isoWeek();
          bonus.year = moment(startDate).year();
          await Api.postBonus(bonus);
        }
      };
      toast.success("Payroll updated successfully.");
      setShowEmployeePayrollModal(false);
      setRefresh(!refresh);
    } catch (err) {
      const msg = err.response?.data?.length ? err.response.data[0].msg : "There was an error with your request";
      toast.error(msg);
      setLoading(false);
    }
  };

  const toPDF = () =>
    payrollToPDF(
      currentWeekPayrollData,
      payrollData,
      startDate,
      endDate,
      categoryId
        ? categoryId === CATEGORY_INSURED
          ? EMPLOYEE_CATEGORIES[0].category
          : EMPLOYEE_CATEGORIES[1].category
        : CATEGORY_ALL
    );

  const handleCategoryChange = (e) => {
    setCategoryId(e.target.value);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await Api.getPayrollData(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        categoryId
      );
      const { payrolls, current_week_payroll_data } = res.data;
      setLoading(false);
      setOriginalStartDate(startDate);
      setPayrollData(payrolls);
      setCurrentWeekPayrollData(current_week_payroll_data);
    } catch (err) {
      const msg = err.response?.data?.length ? err.response.data[0].msg : "There was an error with your request";
      toast.error(msg);
      setLoading(false);
    };
  }, [categoryId, endDate, startDate]);

  useEffect(() => {
    fetchData();
  }, [categoryId, refresh, fetchData]);

  return (
    <div>
      {loading || !payrollData || !currentWeekPayrollData ? (
        <div className="row-container">
          <div className="flex-table text-center">
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </div>
        </div>
      ) : (
        <div className="payroll">
          <Row className="controls-container mx-0 text-left px-0">
            <div className="flex-grow-1 d-flex align-items-center">
              <h4 className="text-left float-left">Payroll Sheet</h4>
              <DateControls
                startDate={startDate}
                originalStartDate={originalStartDate}
                doSubmit={dateSelection}
              ></DateControls>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <Form.Group className="col-6 mb-0 pl-0 d-flex align-items-center">
                <Form.Label className="font-weight-medium mb-0 mr-2">Category</Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  value={categoryId}
                  onChange={handleCategoryChange}
                >
                  <option value="">All</option>
                  {EMPLOYEE_CATEGORIES.map((r, i) => (
                    <option key={i} value={r.value}>
                      {r.category}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Button size="sm" onClick={toPDF}>Generate PDF</Button>
            </div>
          </Row>
          {currentWeekPayrollData.jobs_with_registered_time_this_week
            ?.length ? (
            <div className="table-container col-12" role="table">
              <div className="white-bar">""</div>
              <EmployeePayrollSection
                employees={
                  currentWeekPayrollData.employees_with_registered_time_this_week
                }
              />
              <div className="jobs-section-container col-7">
                <div
                  onClick={(e) =>
                  (e.currentTarget.nextSibling.scrollLeft =
                    e.currentTarget.nextSibling.scrollLeft - SCROLL_STEP)
                  }
                  className="arrow left"
                >
                  <span>
                    <i className="fas fa-chevron-left"></i>
                  </span>
                </div>
                <JobsPayrollSection
                  onClick={employeePayrollClick}
                  jobs={
                    currentWeekPayrollData.jobs_with_registered_time_this_week
                  }
                  employees={
                    currentWeekPayrollData.employees_with_registered_time_this_week
                  }
                  payroll_data={payrollData}
                />
                <div
                  onClick={(e) =>
                  (e.currentTarget.previousSibling.scrollLeft =
                    e.currentTarget.previousSibling.scrollLeft + SCROLL_STEP)
                  }
                  className="arrow right"
                >
                  <span>
                    <i className="fas fa-chevron-right"></i>
                  </span>
                </div>{" "}
                :
              </div>
              <TimesPayrollSection
                onClick={employeePayrollClick}
                employees={
                  currentWeekPayrollData.employees_with_registered_time_this_week
                }
                payroll_data={payrollData}
              />
            </div>
          ) : (
            <div className="no-jobs-alert">
              There are no records for the selected dates
            </div>
          )}
          {showEmployeePayrollModal ? (
            <EmployeePayrollModal
              onSubmit={submitEmployeePayroll}
              onClose={() => setShowEmployeePayrollModal(false)}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default withErrorHandler(withRouter(Payroll));
