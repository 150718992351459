import React, { useState } from 'react';
import withErrorHandler from '../../error/WithErrorHandler';
import './EmployeePayrollModal.scss';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { ACTIONS, usePayroll } from '../../providers/payrollProvider';

const PayrollTravelEntry = ({
    entry,
    index,
}) => {
    const [, setPayrollContext] = usePayroll();
    const [localEntry, setLocalEntry] = useState({ ...entry });
    const [hasError, setHasError] = useState(false);

    const onStartTimeChanged = (e) => {
        const drive_start_time = moment(e.currentTarget.value).toISOString();
        const touchedTrip = {
            ...localEntry,
            drive_start_time
        };
        setLocalEntry(touchedTrip);
        setPayrollContext({
            action: ACTIONS.CHANGE_TOUCHED_TRIPS,
            payload: { touchedTrip }
        });
    };

    const onEndTimeChanged = (e) => {
        const drive_end_time = moment(e.currentTarget.value).toISOString();
        const touchedTrip = {
            ...localEntry,
            drive_end_time
        };
        const hasError = touchedTrip.drive_start_time && drive_end_time &&
            moment(drive_end_time).isBefore(moment(touchedTrip.drive_start_time), 'minutes');
        touchedTrip.hasError = hasError;
        setHasError(hasError);
        setLocalEntry(touchedTrip);
        setPayrollContext({
            action: ACTIONS.CHANGE_TOUCHED_TRIPS,
            payload: { touchedTrip }
        });
    };

    return (
        <tr key={index}>
            <td className='align-middle'>
                <i className="fas fa-car text-primary fa-2x" />
            </td>
            <td>
                <Form.Control
                    type="datetime-local"
                    onChange={onStartTimeChanged}
                    value={localEntry.drive_start_time ? moment(localEntry.drive_start_time).format("YYYY-MM-DDTHH:mm") : ""}
                    required={true}
                />
            </td>
            <td>
                <Form.Control
                    type="datetime-local"
                    onChange={onEndTimeChanged}
                    value={localEntry.drive_end_time ? moment(localEntry.drive_end_time).format("YYYY-MM-DDTHH:mm") : ""}
                    required={true}
                />
                {hasError ? <div className='text-danger font-weight-medium small mt-1'>End time must be after start time</div> : null}
            </td>
        </tr>
    );
};

export default withErrorHandler(PayrollTravelEntry);