import React, { useEffect, useState } from 'react';
import withErrorHandler from '../../error/WithErrorHandler';
import Inputmask from "inputmask";
import { Modal, Button, Form, Spinner, InputGroup } from 'react-bootstrap';
import './EmployeePayrollModal.scss';
import {
    Accordion,
} from 'react-accessible-accordion';

import "react-datetime/css/react-datetime.css";

import { getAllCrew, getRole, getTimeAsHours } from '../../Utils/Utils'
import PayrollJob from './PayrollJob';
import moment from 'moment';
import { ACTIONS, usePayroll } from '../../providers/payrollProvider';

const EmployeePayrollModal = ({
    onSubmit,
    onClose,
}) => {
    const [payrollContext, setPayrollContext] = usePayroll();
    const [loading, setLoading] = useState();

    useEffect(() => {
        let bonus;
        if (payrollContext.payrollData.bonusTime) {
            bonus = {
                ...payrollContext.payrollData.bonusTime,
                durationFormatted: getTimeAsHours(payrollContext.payrollData.bonusTime.duration).toString()
            };
        } else {
            bonus = {
                employee_id: payrollContext.payrollData.employee.id,
                duration: 0,
                durationFormatted: "00:00"
            };
        };
        setPayrollContext({
            action: ACTIONS.CHANGE_BONUS,
            payload: { bonus }
        });
    }, [payrollContext.payrollData.bonusTime, payrollContext.payrollData.employee.id, setPayrollContext]);

    useEffect(() => {
        const selector = document.getElementsByClassName("hours-input");
        const im = Inputmask({ regex: "[0-2][0-9]:[0-5][0-9]" });
        for (let i = 0; i < selector.length; i++) {
            im.mask(selector.item(i));
        };
    }, []);


    const doSubmit = (evt) => {
        evt.preventDefault();
        if (loading) { return };
        setLoading(true);
        return onSubmit();
    };

    const getJob = (payrollJob) => {
        const job = payrollContext.jobs
            .filter(job => {
                const allCrew = getAllCrew(job);
                const exists = allCrew.find(e => e.id === payrollContext.payrollData.employee.id);
                return exists;
            })
            .find(j => j.id === payrollJob.job_id);
        return job;
    };

    return payrollContext.payrollData ? (
        <Modal className="employee-payroll-modal" show={true} onHide={() => onClose()} size="lg">
            <Form onSubmit={doSubmit} className="employee-payroll-form d-flex flex-column">
                <Modal.Header closeButton>
                    <Modal.Title>{`Edit Employee Payroll - ${payrollContext.payrollData.employee.name}`}</Modal.Title>
                    {getRole(payrollContext.payrollData.employee)}
                </Modal.Header>
                <Modal.Body>
                    {payrollContext.payrollData.job_hours.length ? (
                        <Accordion
                            className="col-12 p-0"
                            allowZeroExpanded={true}
                            allowMultipleExpanded={true}
                            preExpanded={[]}
                        >
                            {payrollContext.payrollData.job_hours
                                .map((payrollJob, index) => {
                                    const job = getJob(payrollJob);
                                    return (
                                        <PayrollJob
                                            key={index}
                                            job={job}
                                            payrollJob={payrollJob}
                                        />
                                    )
                                })}
                        </Accordion >
                    ) : null}
                    <Form.Group className="col-12 p-0 col-md-6 mt-3">
                        <Form.Label>Bonus Time</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text><i className="fas fa-gift text-primary"></i></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                className="hours-input"
                                placeholder="Set new value"
                                onChange={(evt) => {
                                    const duration = moment.duration(evt.currentTarget.value).asMinutes();
                                    const durationFormatted = getTimeAsHours(duration).toString();
                                    const bonus = {
                                        ...payrollContext.bonus,
                                        duration,
                                        durationFormatted
                                    };
                                    setPayrollContext({
                                        action: ACTIONS.CHANGE_BONUS,
                                        payload: { bonus }
                                    });
                                }}
                                defaultValue={payrollContext.bonus?.durationFormatted || ""}
                                type='text'
                            />
                        </InputGroup>
                    </Form.Group>
                </Modal.Body >
                <Modal.Footer className='d-flex justify-content-between mt-2'>
                    <Button variant="secondary" onClick={onClose}>Cancel</Button>
                    <Button className="bg-primary" variant="primary" type="submit">
                        {loading ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : 'Save'}
                    </Button>
                </Modal.Footer>
            </Form >
        </Modal >
    ) : null;
};

export default withErrorHandler(EmployeePayrollModal);