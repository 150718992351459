import React from 'react';
import withErrorHandler from './error/WithErrorHandler';
import './App.scss';
import Routes from './Routes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { JobDetailsProvider } from './providers/jobDetailsProvider';
import { AuthProvider } from './providers/authProvider';
import { PayrollProvider } from './providers/payrollProvider';

toast.configure({
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true
});

const App = () => {

    return (
      <div className="App">
        <div className="app-body p-0 col-12 overflow-x-hidden">
          <AuthProvider>
            <JobDetailsProvider>
              <PayrollProvider>
                <Routes />
              </PayrollProvider>
            </JobDetailsProvider>
          </AuthProvider>
        </div>
      </div>
    );
}

export default withErrorHandler(App);
