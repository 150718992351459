const JOB_STATUSES = [
  { value: "0", name: 'Tentative', class: 'tentative' },
  { value: "1", name: 'Scheduled', class: 'scheduled' },
  { value: "3", name: 'In Progress', class: 'inprogress' },
  { value: "2", name: 'Completed', class: 'completed' },
  { value: "4", name: 'Invoiced', class: 'invoiced' },
  { value: "5", name: 'Received', class: 'received' }
];

const CUSTOMER_STATUSES = [
  { value: "1", name: 'Active', class: 'completed' },
  { value: "2", name: 'Inactive', class: 'invoiced' },
];

const EMPLOYEE_STATUSES = [
  { value: "1", name: 'Active', class: 'completed' },
  { value: "2", name: 'Inactive', class: 'invoiced' },
];

const EMPLOYEE_ROLES = [
  { value: "1", name: 'Technician', class: 'technician' },
  { value: "2", name: 'Foreman', class: 'foreman' },
  { value: "3", name: 'Executive', class: 'executive' }
];

const EMPLOYEE_CATEGORIES = [
  { value: "1", category: 'Insured' },
  { value: "2", category: 'Non-Insured' },
];

const CATEGORY_INSURED = 1;

const USER_ROLE_TECHNICIAN = '1';
const USER_ROLE_FOREMAN = '2';
const USER_ROLE_EXECUTIVE = '3';


export {
  JOB_STATUSES,
  CUSTOMER_STATUSES,
  EMPLOYEE_STATUSES,
  EMPLOYEE_ROLES,
  EMPLOYEE_CATEGORIES,
  CATEGORY_INSURED,
  USER_ROLE_TECHNICIAN,
  USER_ROLE_FOREMAN,
  USER_ROLE_EXECUTIVE,
}
