import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

const CustomerField = (props) => {
  let separator;
  let separatorClass;
  if (props.isReadOnly) {
    separator = ': ';
    separatorClass = '';
  } else {
    if (props.isRequired) {
      separator = ' *';
      separatorClass = 'red-asterisk';
    }
  }

  return (
    <Form.Group className={props.groupClasses ?? 'col-12'}>
      <Form.Label>
        <span> {props.name}</span>
        <span className={separatorClass}>{separator}</span>
        {props.isReadOnly && (
          <span className='read-only-value'>{props.value || '-'}</span>
        )}
      </Form.Label>
      {!props.isReadOnly && (
        <Form.Control
          required={props.isRequired}
          type={props.type ?? 'text'}
          placeholder={props.placeholder}
          value={props.value || ""}
          data-value={props.dataValue}
          onChange={props.valueChange}
        />
      )}
    </Form.Group>
  );
};

CustomerField.propTypes = {
  dataValue: PropTypes.string,
  groupClasses: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  valueChange: PropTypes.func,
};

export default CustomerField;
