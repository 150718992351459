import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './JobDatePicker.css';
import moment from 'moment';

export default class JobDatePicker extends React.Component {

  static defaultProps = {
    numberOfMonths: 1,
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const { start_date, end_date } = this.props;
    return {
      from: start_date ? new Date(start_date) : null,
      to: end_date ? new Date(end_date) : null,
    };
  }

  handleDayClick = day => {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
    this.props.onSelection(range);
  }

  toggleCalendar = () => {
    this.props.toggleCalendar();
    this.setState(this.getInitialState());
  }

  render() {
    const { numberOfMonths } = this.props;
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };

    return (
      <div className="text-center calendar-container">
        <div className="m-0 text-left overflow-hidden">
          {!from && !to && <p className="float-left">Please select the first day.</p>}
          {from && !to && <p className="float-left">Please select the last day.</p>}
          {from &&
            to &&
            <span className="float-left">{`Selected from ${moment(from).format("YYYY-MM-DD")} to ${moment(to).format("YYYY-MM-DD")}`}{' '}</span>}
          <div>
            <button className="link float-right bg-primary btn btn-primary done-btn" onClick={this.toggleCalendar}>
              Done
            </button>
          </div>
        </div>
        <DayPicker
          className="job-date-picker"
          numberOfMonths={numberOfMonths}
          selectedDays={[from, { from, to }]}
          month={from || to || new Date()}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
        />
      </div>
    );
  }
}