import React from 'react';
import withErrorHandler from '../error/WithErrorHandler';
import { Row } from 'react-bootstrap';
import ChangeOrder from './ChangeOrder';
import { useJobDetail } from '../providers/jobDetailsProvider';

const PostContract = () => {
    const [jobDetailContext] = useJobDetail();

    const wos = jobDetailContext.jobDays.reduce((p, c) => [...p, ...c.workOrders.map(wo => Object.assign(wo, { date: c.date }))], []);
    const standByTimes = jobDetailContext.jobDays.reduce((p, c) => [...p, ...c.standByTime.map(wo => Object.assign(wo, { date: c.date }))], []);
    return (
        <div className="printable-post-contract print-border mb-2">
            <Row className="bg-success print-only inner-section justify-content-around bg-primary py-3 my-0">
                <span className="font-weight-medium font-size-14 text-white">Post Contract</span>
            </Row>
            <Row className="col-12 post-contract pt-3 my-0 inner-section justify-content-around align-items-center">
                <div className="col-1 text-truncate badge p-2 completed">{jobDetailContext.job.postContract.date ? jobDetailContext.job.postContract.date.substr(0, 10) : ''}</div>
                <div className="col-5 text-truncate">
                    <span className="font-weight-medium">Customer Name:</span>
                    <span>{jobDetailContext.job.customer.name}</span>
                </div>
                <div className="col-6 text-truncate">
                    <span className="font-weight-medium">Scope of Work:</span>
                    <span>{jobDetailContext.job.description}</span>
                </div>
            </Row>
            <Row className="inner-section justify-content-around bg-primary py-2 my-0 bg-success">
                <span className="font-weight-medium font-size-14 text-white">Extra Work</span>
            </Row>
            <Row className="post-contract py-2 my-0 inner-section align-items-center flex-wrap">
                {wos.length ? wos.map((wo, index) =>
                    <div key={index} className="col-12">
                        <ChangeOrder item={wo} postContract={jobDetailContext.job.postContract} />
                    </div>
                ) : <div className="col-12">No Extra Work</div>}
            </Row>
            <Row className="inner-section justify-content-around bg-primary py-2 my-0 bg-success">
                <span className="font-weight-medium font-size-14 text-white">Stand by Time</span>
            </Row>
            <Row className="post-contract py-2 my-0 inner-section align-items-center flex-wrap">
                {standByTimes.length ? standByTimes.map((standByTime, index) =>
                    <div key={index} className="col-12">
                        <ChangeOrder item={standByTime} postContract={jobDetailContext.job.postContract} />
                    </div>
                ) : <div className="col-12">No Stand by Time</div>}
            </Row>
            <Row className="inner-section justify-content-around bg-primary py-2 my-0 bg-success">
                <span className="font-weight-medium font-size-14 text-white">Client Representative</span>
            </Row>
            <Row className="inner-section justify-content-around my-0 border rounded-bottom bg-white text-left p-4 border-top-0">
                <div className="col-4">
                    <div>
                        <span>Name: </span>
                        <span>{jobDetailContext.job.postContract.signature_name}</span>
                    </div>
                    <div>
                        <span>Date: </span>
                        <span>{jobDetailContext.job.postContract.date ? jobDetailContext.job.postContract.date.substr(0, 10) : 'N/A'}</span>
                    </div>
                </div>
                <div className="col-4 horizontal-center-content">
                    <span>Signature: </span>
                    <span>
                        <img src={jobDetailContext.job.postContract.signature_url} alt="" height="100px" />
                    </span>
                </div>
                <div className="col-4 p-0 vertical-center-content">
                    <div>
                        <span>Elected to Perform Walk-Through:</span>
                        <span className={`walk-through ${jobDetailContext.job.postContract.walk_through_elected ? 'yes' : 'no'}`}>{jobDetailContext.job.postContract.walk_through_elected ? 'Yes' : 'No'}</span>
                    </div>
                </div>
                <div className="col-2"></div>
                <div className="col-8 text-justify text-muted">
                    <span>
                        <span className="font-weight-medium">
                            By signing you are agreeing Faulkner Industrial Maintenance Co. Inc. has completed the work it was contracted to 
                            perform to your satisfaction. All requirements and requests have been met. At this time the work invoice may be issued 
                            and payment terms begin.
                        </span>
                    </span>
                </div>
                <div className="col-2"></div>
            </Row>
        </div>
    );
};

export default withErrorHandler(PostContract);