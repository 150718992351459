import React from 'react';
import withErrorHandler from '../error/WithErrorHandler';
import './EmployeePayrollSection.scss';

const EmployeePayrollSection = ({employees}) => {
        return (
            <div className="payroll-employees col-2 float-left p-0">
                <div className="flex-table header" role="rowgroup">
                    <div className="flex-row first" role="columnheader">
                        Employees <span className="text-primary">({employees.length})</span>
                    </div>
                </div>
                {employees.sort((x, y) => x.firstName < y.firstName ? -1 : 1).map((e, i) =>
                    <div key={i} className="flex-table row" role="rowgroup">
                        <div className='flex-row first' role="cell">
                            <span className="text-primary">#{e.number} </span>
                            <span style={{ marginLeft: '5px' }}>{`${e.firstName} ${e.lastName}`}</span>
                        </div>
                    </div>)
                }
            </div>
        );
}

export default withErrorHandler(EmployeePayrollSection);